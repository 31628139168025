import "./navDesktop.scss";
import { NavLink, Link } from "react-router-dom";
import { useRef, useEffect } from "react";
import gsap from "gsap";

export const NavDesktop = () => {
  return (
    <div className="navdesktop">
      <div className="logo__wrapper">
        <Link to="/" className="logo">
          MW
        </Link>
      </div>
      <ul className="navdesktop__list">
        <NavLink className="navdesktop__link" to="/design">
          Design
        </NavLink>

        <NavLink className="navdesktop__link" to="/art">
          Art
        </NavLink>

        <NavLink className="navdesktop__link" to="/code">
          Code
        </NavLink>

        <NavLink className="navdesktop__link" to="/about">
          About
        </NavLink>
      </ul>
    </div>
  );
};

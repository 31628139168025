import Sketch from "react-p5";
import bg from "../../source/images/background/background-home-grey-xl.png";

export const P5Desktop = () => {
  let bgImg;

  const preload = (p5) => {
    bgImg = p5.loadImage(bg);
  };

  const setup = (p5, canvasParentRef) => {
    p5.createCanvas(p5.displayWidth, p5.displayHeight).parent(canvasParentRef);
    p5.noStroke();
  };

  const draw = (p5) => {
    p5.ellipse(p5.mouseX, p5.mouseY, 170, 170, p5.random(20, 80));
    p5.fill(64, 60, 226, 150);
    p5.noStroke();
    p5.frameRate(10);
  };

  const resetBg = (p5) => {
    p5.setup();
  };

  return (
    <Sketch
      mouseClicked={resetBg}
      preload={preload}
      draw={draw}
      setup={setup}
    />
  );
};

import { useEffect, useRef } from "react";
import gsap from "gsap";

export const GsapAnimation = () => {
  const animationRef = useRef(null);
  useEffect(() => {
    const el = animationRef.current;
    gsap.fromTo(
      el,
      { y: 100, opacity: 0.2 },
      { y: 0, opacity: 1, duration: 0.8 }
    );
  }, []);
  return { animationRef };
};

import "./DesignPage.scss";
import Item from "../../components/item/Item";
import { useContext, useEffect, useState } from "react";
import { BurgerBarContext } from "../../context/burgerBarContextProvider/burgerBarContextProvider";
import { GsapAnimation } from "../../hooks/GsapAnimation";
import * as contentful from "contentful";

const DesignPage = () => {
  const { open } = useContext(BurgerBarContext);
  const { animationRef } = GsapAnimation();
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    // Initialize Contentful client
    const client = contentful.createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
      accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
    });

    // Fetch entries from Contentful
    client
      .getEntries({
        content_type: "design", // Replace 'blogPost' with your content type ID
      })
      .then((response) => {
        // Set the retrieved posts in state
        let items = response.items.map((post, index) => {
          let previousObj;
          if (index === 0) {
            previousObj = response.items[response.items.length - 1];
          }
          if (index > 0) {
            previousObj = response.items[index - 1];
          }
          post.previousObj = previousObj;
          return post;
        });
        setPosts(items);
      })
      .catch(console.error);
  }, []);

  // SORT
  const [sortedData, setSortedData] = useState([]);

  const sortById = () => {
    return setSortedData(
      [...posts].sort((a, b) => a.fields.order - b.fields.order)
    );
  };

  useEffect(() => {
    sortById();
  }, [posts]);

  console.log("posts: ", posts);
  console.log("sortedData: ", sortedData);

  return (
    <div
      ref={animationRef}
      className={open ? "item-container--navopen" : "item-container"}
    >
      {sortedData.map((post, index) => {
        return (
          <Item
            key={post.fields.id}
            page={post.fields.id}
            linkId={post.fields.id}
            title={post.fields.title}
            placeholderSrc={post.fields.thumbnail.fields.file.url + "?q=1"}
            image={post.fields.thumbnail.fields.file.url}
          />
        );
      })}
    </div>
  );
};
export default DesignPage;

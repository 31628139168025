import { useRoutes } from "react-router-dom";
import {
  HomePage,
  AboutPage,
  ArtPage,
  CodePage,
  DesignPage,
  CasePage,
} from "../pages/index";

export const AppRouter = () => {
  const routes = useRoutes([
    { path: "/", element: <HomePage /> },
    { path: "/about", element: <AboutPage /> },
    { path: "/design", element: <DesignPage /> },
    { path: "/design/:caseId", element: <CasePage /> },
    { path: "/art", element: <ArtPage /> },
    { path: "/art/:caseId", element: <CasePage /> },
    { path: "/code", element: <CodePage /> },
    { path: "/code/:caseId", element: <CasePage /> },
  ]);
  return routes;
};

import { NavDesktop } from "../navDesktop/navDesktop";
import { NavMobile } from "../navMobile/navMobile";
import { useEffect } from "react";
import { useWindowResize } from "../../../hooks/useWindowResize";

export const NavApp = () => {
  const { width, handleResizeWindow, breakpoint } = useWindowResize();

  useEffect(() => {
    window.addEventListener("resize", handleResizeWindow);
    return () => window.removeEventListener("resize", handleResizeWindow);
  }, []);
  return width > breakpoint ? <NavDesktop /> : <NavMobile />;
};

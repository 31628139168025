import { Link } from "react-router-dom";
import "./caseLink.scss";
import React from "react";

const CaseLink = ({ title, thumbnail, linkId, page }) => {
  return (
    <div className="item">
      <Link to={`/${page}/${linkId}`}>
        <h5 className="caselink-title">{title}</h5>
        <div className="img-wrapper">
          <img className="img" src={thumbnail} alt={thumbnail} />
        </div>
      </Link>
    </div>
  );
};

export default CaseLink;

import "./Item.scss";
import React from "react";
import { Link } from "react-router-dom";
import ProgressiveImage from "react-progressive-graceful-image";

const Item = ({ title, placeholderSrc, image, linkId, iRef }) => {
  return (
    <>
      <Link to={linkId}>
        <div ref={iRef} className="item">
          <h4 className="item-title">{title}</h4>
          <div className="img-wrapper">
            <ProgressiveImage src={image} placeholder={placeholderSrc}>
              {(src, loading) => (
                <img
                  className={`image${loading ? " loading" : " loaded"}`}
                  src={src}
                  alt="img"
                />
              )}
            </ProgressiveImage>
          </div>
        </div>
      </Link>
    </>
  );
};

export default Item;

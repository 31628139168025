import { ReactP5Wrapper } from "react-p5-wrapper";

function sketch(p5) {
  let backgroundColor = "#F1F1F1";
  let ball;
  let balls = [];

  p5.setup = () => {
    p5.createCanvas(p5.displayWidth, p5.displayHeight);
    ball = new Ball();
    for (let i = 0; i < 10; i++) {
      balls.push(new Ball());
    }
  };

  p5.draw = () => {
    p5.background(backgroundColor);

    console.log(p5.displayWidth, p5.displayHeight);

    for (let i = 0; i < balls.length; i++) {
      balls[i].move();
      balls[i].display();
    }
  };

  class Ball {
    pos;
    vel;
    radius;

    constructor() {
      this.pos = p5.createVector(
        p5.random(1, p5.displayWidth),
        p5.random(1, p5.displayHeight)
      );
      this.vel = p5.createVector(p5.random(1, 3), p5.random(1, 3));
      this.radius = 50;
    }

    move() {
      // this.vel.y += 0.1;
      this.pos.add(this.vel);
      if (this.pos.x < this.radius) {
        this.pos.x = this.radius;
        this.vel.x = -this.vel.x;
      }
      if (this.pos.x > p5.displayWidth - this.radius) {
        this.pos.x = p5.displayWidth - this.radius;
        this.vel.x = -this.vel.x;
      }
      if (this.pos.y < this.radius) {
        this.pos.y = this.radius;
        this.vel.y = -this.vel.y;
      }
      if (this.pos.y > p5.displayHeight - this.radius) {
        this.pos.y = p5.displayHeight - this.radius;
        this.vel.y = -this.vel.y;
      }
    }

    display() {
      p5.stroke(0);
      p5.fill(64, 60, 226, 110);
      p5.noStroke();
      p5.ellipse(this.pos.x, this.pos.y, this.radius * 2);
    }
  }
}

export function P5Mobile() {
  return <ReactP5Wrapper sketch={sketch} />;
}

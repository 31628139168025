import "./App.scss";
import { BrowserRouter as Router } from "react-router-dom";
import { AppRouter } from "./routing/AppRouter";
import { BurgerBarContextProvider } from "./context/burgerBarContextProvider/burgerBarContextProvider";
import { NavApp } from "./components/navigation/navApp/navApp";
// import caseData from "./data/caseData.js";
import ScrollToTop from "./hooks/ScrollToTop";

const App = () => {
  return (
    <div className="App">
      <Router>
        <BurgerBarContextProvider>
          <NavApp />
          <ScrollToTop />
          <AppRouter />
        </BurgerBarContextProvider>
      </Router>
    </div>
  );
};

export default App;

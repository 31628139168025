import "./AboutPage.scss";
import backgroundxs from "../../source/images/background/background-about-white-xs.svg";
import backgroundm from "../../source/images/background/background-about-white-m.svg";

import { useEffect, useRef } from "react";
import { useWindowResize } from "../../hooks/useWindowResize";
import eLink from "../../source/images/icons/link_icon.svg";
import gsap from "gsap";

const AboutPage = () => {
  const { width, handleResizeWindow } = useWindowResize();
  useEffect(() => {
    window.addEventListener("resize", handleResizeWindow);
    return () => window.removeEventListener("resize", handleResizeWindow);
  }, [handleResizeWindow]);

  const animationRef1 = useRef(null);
  useEffect(() => {
    const el = animationRef1.current;
    gsap.fromTo(
      el,
      { y: 100, opacity: 0.2 },
      { y: 0, opacity: 1, duration: 0.8 }
    );
  }, []);

  const animationRef2 = useRef(null);
  useEffect(() => {
    const el = animationRef2.current;
    gsap
      .fromTo(el, { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 0.8 })
      .delay(0);
  }, []);

  const animationRef3 = useRef(null);
  useEffect(() => {
    const el = animationRef3.current;
    gsap
      .fromTo(el, { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 0.8 })
      .delay(0.4);
  }, []);

  const animationRef4 = useRef(null);
  useEffect(() => {
    const el = animationRef4.current;
    gsap
      .fromTo(el, { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 0.8 })
      .delay(0.6);
  }, []);

  return (
    <div className="bg">
      <div className="about">
        <div ref={animationRef1} className="about-item about-item1">
          <h2 className="about-title">About</h2>
          <p>
            I’m an extroverted Visual Designer and Frontend Developer. Creating
            valuable projects that improve the lives of others is what make me
            tick. With each project I aim to learn a new skill or insight to
            challenge my views or abilities.
          </p>
          <div className="platforms">
            <div className="platforms-item">
              <a
                className=" platforms-link"
                href="https://linkedin.com/in/moira-w-373085b6"
              >
                LinkedIn
              </a>
              <img className="eLink" src={eLink} alt="eLink" />
            </div>
            <div className="platforms-item">
              <a
                className="platforms-link"
                href="https://github.com/MoiraWalker"
              >
                Github
              </a>
              <img className="eLink" src={eLink} alt="eLink" />
            </div>
          </div>
        </div>

        <div ref={animationRef2} className="about-item about-item2">
          <h2 className="about-title">Design</h2>
          <p>
            Through research, testing and iteration I channel the clients goals
            into design solutions that fulfill the needs of the end-user. I aim
            to find the most minimalistic solution in which function and estetic
            meet.
          </p>
        </div>
        <div ref={animationRef3} className="about-item about-item3">
          <h2 className="about-title">Art</h2>
          <p>
            Through art I give my thoughts and fascinations a stage. I like to
            experiment in different forms of media to express a concept. I
            admire how art is fluid, personal and has no limits.
          </p>
        </div>
        <div ref={animationRef4} className="about-item about-item4">
          <h2 className="about-title">Code</h2>
          <p>
            I love diving into complexity and see beauty in logic. Because of my experience as a front-end developer I can communicate effectively with developers and bridge the gap between creativity and technology. 
          </p>
        </div>
      </div>

      {width < 500 && (
        <img className="mobilebg" src={backgroundxs} alt={backgroundxs} />
      )}
      {width > 500 && width < 1000 && (
        <img className="mobilebg" src={backgroundm} alt={backgroundm} />
      )}
    </div>
  );
};
export default AboutPage;

import "./HomePage.scss";
import { P5Desktop } from "../../components/P5/P5Desktop";
import { P5Mobile } from "../../components/P5/P5Mobile";
import { useEffect } from "react";
import { useWindowResize } from "../../hooks/useWindowResize";
import backgroundm from "../../source/images/background/background-home-grey-m.svg";
import { GsapAnimation } from "../../hooks/GsapAnimation";

const HomePage = () => {
  const { width, handleResizeWindow, breakpoint } = useWindowResize();
  const { animationRef } = GsapAnimation();

  useEffect(() => {
    window.addEventListener("resize", handleResizeWindow);
    return () => window.removeEventListener("resize", handleResizeWindow);
  }, []);

  return (
    <div className="home">
      <div ref={animationRef} className="home__title">
        <h1>Moira</h1>
        <h1>Walker</h1>
      </div>
      {width > breakpoint && <P5Desktop />}
      {width < breakpoint && <P5Mobile />}

      {/* {width < 1000 && (
        <img className="mobilebg-home" src={backgroundm} alt={backgroundm} />
      )} */}
    </div>
  );
};
export default HomePage;

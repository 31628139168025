import "./CasePage.scss";
import { useParams } from "react-router-dom";
import caseData from "../../data/caseData";
import CaseLink from "../../components/caselink/caselink";
import ReactPlayer from "react-player";
import gsap from "gsap";
import { GsapAnimation } from "../../hooks/GsapAnimation";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useEffect, useState } from "react";
import * as contentful from "contentful";
import ProgressiveImage from "react-progressive-graceful-image";
import eLink from "../../source/images/icons/link_icon.svg";

const CasePage = () => {
  const { caseId } = useParams();
  const { animationRef } = GsapAnimation();
  gsap.registerPlugin(ScrollTrigger);

  const [cases, setCases] = useState([]);
  const [caseObject, setCaseObject] = useState();
  const [posts, setPosts] = useState([]);

  // RETRIEVE USECASES
  useEffect(() => {
    const client = contentful.createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
      accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
    });
    client
      .getEntries({
        content_type: "case",
      })
      .then((response) => {
        setCases(response.items);
      })
      .catch(console.error);
  }, []);

  // FIND CURRENT CASE OBJECT
  useEffect(() => {
    setCaseObject(getObjectById(cases, caseId));
  }, [caseId, cases]);

  let contentType;
  if (caseObject?.fields?.page === "art") {
    contentType = "art";
  }
  if (caseObject?.fields?.page === "design") {
    contentType = "design";
  }
  if (caseObject?.fields?.page === "code") {
    contentType = "code";
  }

  //  POSTS
  useEffect(() => {
    const client = contentful.createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
      accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
    });

    client
      .getEntries({
        content_type: contentType,
      })
      .then((response) => {
        setPosts(response.items);
      })
      .catch(console.error);
  }, [contentType]);

  const getObjectById = (array, id) => {
    return array.find((obj) => obj.fields.id === id);
  };

  // FIND OBJECTS
  const specificId = caseId;
  const currentObj = posts.filter((obj) => obj?.fields?.id === specificId);
  let orderNumber = currentObj[0]?.fields?.order;
  let previousOrder;
  let nextOrder;
  if (orderNumber === 0) {
    previousOrder = posts.length - 1;
    nextOrder = orderNumber + 1;
  }
  if (orderNumber > 0) {
    previousOrder = orderNumber - 1;
    nextOrder = orderNumber + 1;
  }
  if (orderNumber === posts.length - 1) {
    previousOrder = posts.length - 2;
    nextOrder = 0;
  }
  const previousObj = posts.filter(
    (obj) => obj?.fields?.order === previousOrder
  );
  const nextObj = posts.filter((obj) => obj?.fields?.order === nextOrder);

  console.log("caseobjet", caseObject);
  console.log("url ..", caseObject?.fields?.description.content[0].content[1]);
  console.log(
    "this website",
    caseObject?.fields?.description.content[0].content[1]?.content[0].value
  );
  console.log(
    "url",
    caseObject?.fields?.description.content[0].content[1]?.data?.uri
  );

  return (
    <div ref={animationRef} className="case-container">
      <h2 className="case-title">{caseObject?.fields?.title}</h2>
      <p className="case-wrapper">
        {caseObject?.fields?.description.content[0].content[0].value}
        {caseObject?.fields?.description.content[0].content[1]?.data?.uri && (
          <span>
            <a
              className="platforms-link"
              href={
                caseObject?.fields?.description.content[0].content[1]?.data?.uri
              }
              target="_blank"
            >
              {
                caseObject?.fields?.description.content[0].content[1]
                  ?.content[0].value
              }
            </a>
            <img className="eLink" src={eLink} alt="eLink" />
          </span>
        )}
      </p>

      {caseObject?.fields?.video && (
        <div className="case-wrapper">
          <div className="wrapper">
            <ReactPlayer
              className="player"
              controls={true}
              url={caseObject?.fields?.video?.fields?.file?.url}
              width="100%"
              height="100%"
            />
          </div>
        </div>
      )}
      {caseObject?.fields?.image1 && (
        <div className="case-wrapper">
          <ProgressiveImage
            src={caseObject?.fields?.image1.fields.file.url}
            placeholder={caseObject?.fields?.image1.fields.file.url + "?q=1"}
          >
            {(src, loading) => (
              <img
                className={`image${loading ? " loading" : " loaded"}`}
                src={src}
                alt={caseObject?.fields?.title}
                loading="lazy"
              />
            )}
          </ProgressiveImage>
        </div>
      )}
      {caseObject?.fields?.image2 && (
        <div className="case-wrapper">
          <ProgressiveImage
            src={caseObject?.fields?.image2.fields.file.url}
            placeholder={caseObject?.fields?.image2.fields.file.url + "?q=1"}
          >
            {(src, loading) => (
              <img
                className={`image${loading ? " loading" : " loaded"}`}
                src={src}
                alt={caseObject?.fields?.title}
                loading="lazy"
              />
            )}
          </ProgressiveImage>
        </div>
      )}
      {caseObject?.fields?.image3 && (
        <div className="case-wrapper">
          <ProgressiveImage
            src={caseObject?.fields?.image3.fields.file.url}
            placeholder={caseObject?.fields?.image3.fields.file.url + "?q=1"}
          >
            {(src, loading) => (
              <img
                className={`image${loading ? " loading" : " loaded"}`}
                src={src}
                alt={caseObject?.fields?.title}
                loading="lazy"
              />
            )}
          </ProgressiveImage>
        </div>
      )}
      {caseObject?.fields?.image4 && (
        <div className="case-wrapper">
          <ProgressiveImage
            src={caseObject?.fields?.image4.fields.file.url}
            placeholder={caseObject?.fields?.image4.fields.file.url + "?q=1"}
          >
            {(src, loading) => (
              <img
                className={`image${loading ? " loading" : " loaded"}`}
                src={src}
                alt={caseObject?.fields?.title}
                loading="lazy"
              />
            )}
          </ProgressiveImage>
        </div>
      )}
      {caseObject?.fields?.image5 && (
        <div className="case-wrapper">
          <ProgressiveImage
            src={caseObject?.fields?.image5.fields.file.url}
            placeholder={caseObject?.fields?.image5.fields.file.url + "?q=1"}
          >
            {(src, loading) => (
              <img
                className={`image${loading ? " loading" : " loaded"}`}
                src={src}
                alt={caseObject?.fields?.title}
                loading="lazy"
              />
            )}
          </ProgressiveImage>
        </div>
      )}
      {caseObject?.fields?.image6 && (
        <div className="case-wrapper">
          <ProgressiveImage
            src={caseObject?.fields?.image6.fields.file.url}
            placeholder={caseObject?.fields?.image6.fields.file.url + "?q=1"}
          >
            {(src, loading) => (
              <img
                className={`image${loading ? " loading" : " loaded"}`}
                src={src}
                alt={caseObject?.fields?.title}
                loading="lazy"
              />
            )}
          </ProgressiveImage>
        </div>
      )}
      {caseObject?.fields?.image7 && (
        <div className="case-wrapper">
          <ProgressiveImage
            src={caseObject?.fields?.image7.fields.file.url}
            placeholder={caseObject?.fields?.image7.fields.file.url + "?q=1"}
          >
            {(src, loading) => (
              <img
                className={`image${loading ? " loading" : " loaded"}`}
                src={src}
                alt={caseObject?.fields?.title}
                loading="lazy"
              />
            )}
          </ProgressiveImage>
        </div>
      )}
      <h3>Other projects</h3>
      {
        <div className="caselink-wrapper">
          {
            <CaseLink
              page={previousObj[0]?.fields?.page}
              linkId={previousObj[0]?.fields?.id}
              title={previousObj[0]?.fields?.title}
              thumbnail={previousObj[0]?.fields?.thumbnail?.fields?.file?.url}
            />
          }
          {
            <CaseLink
              page={nextObj[0]?.fields?.page}
              linkId={nextObj[0]?.fields?.id}
              title={nextObj[0]?.fields?.title}
              thumbnail={nextObj[0]?.fields?.thumbnail?.fields?.file?.url}
            />
          }
        </div>
      }
    </div>
  );
};
export default CasePage;

import "./navMobile.scss";
import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { Twirl as Hamburger } from "hamburger-react";

export const NavMobile = () => {
  const [open, setOpen] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const ref = useRef(true);

  useEffect(() => {
    const firstRender = ref.current;

    if (firstRender) {
      ref.current = false;
      setFirstRender(true);
      console.log("First Render");
    } else {
      setFirstRender(false);
      console.log("Not a first Render");
    }
  });

  console.log("firstrender", firstRender);

  return (
    <div className="navmobile">
      <div className="logo_and_navburger">
        <div className="logo__wrapper">
          <Link to="/" className="logo">
            MW
          </Link>
        </div>
        <div className="hamburger-react-wrapper">
          <Hamburger
            size={42}
            color={open ? "white" : "black"}
            rounded
            toggled={open}
            toggle={setOpen}
            distance="lg"
            easing="ease"
            duration={1}
          />
        </div>
      </div>

      {!firstRender && (
        <div className={open ? "navmobile--open" : "navmobile--closed"}>
          <ul className="navmobile__list">
            <Link
              className="navmobile__link"
              onClick={() => setOpen(!open)}
              to="/"
            >
              Home
            </Link>
            <Link
              className="navmobile__link"
              onClick={() => setOpen(!open)}
              to="/design"
            >
              Design
            </Link>
            <Link
              className="navmobile__link"
              onClick={() => setOpen(!open)}
              to="/art"
            >
              Art
            </Link>
            <Link
              className="navmobile__link"
              onClick={() => setOpen(!open)}
              to="/code"
            >
              Code
            </Link>
            <Link
              className="navmobile__link"
              onClick={() => setOpen(!open)}
              to="/about"
            >
              About
            </Link>
          </ul>
        </div>
      )}
    </div>
  );
};
